<template>
  <b-sidebar
    id="add-new-rate-sidebar"
    :visible="isAddNewCouponSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="onFormToggle"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{isEdit ? 'Update Coupon' : 'Add New Coupon'}}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Title -->
          <validation-provider
            #default="validationContext"
            name="Title"
            rules="required"
          >
            <b-form-group
              label="Title"
              label-for="title"
              :state="getValidationState(validationContext)"
            >
             <b-form-input
                id="title"
                v-model="couponData.title"
                autofocus
                trim
                :state="getValidationState(validationContext)"
                placeholder="Title"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Code -->
          <validation-provider name="Code" #default="validationContext" rules="required">
            <b-form-group
              label="Code"
              label-for="code"
              :state="getValidationState(validationContext)"
            >
             <b-form-input
                id="code"
                v-model="couponData.code"
                autofocus
                trim
                :state="getValidationState(validationContext)"
                placeholder="Code"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="Description"
          >
            <b-form-group label="Description" label-for="description">
              <b-form-input
                id="description"
                v-model="couponData.description"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Description"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Usage Count -->
          <validation-provider
            #default="validationContext"
            name="UsageCount"
            rules="required"
          >
            <b-form-group label="UsageCount" label-for="usage-count">
              <b-form-input
                id="usage-count"
                v-model="couponData.usageCount"
                type="number"
                min="0"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Min Amount -->
          <validation-provider
            #default="validationContext"
            name="MinAmount"
            rules="required"
          >
            <b-form-group label="MinAmount" label-for="min-amount">
              <b-form-input
                id="min-amount"
                v-model="couponData.minAmount"
                type="number"
                min="0"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Max Amount -->
          <validation-provider
            #default="validationContext"
            name="Max Amount"
            rules="required"
          >
            <b-form-group label="Max Amount" label-for="max-amount">
              <b-form-input
                id="max-amount"
                v-model="couponData.maxAmount"
                type="number"
                min="0"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Discount Percent -->
          <validation-provider
            #default="validationContext"
            name="Discount Percent"
            rules="required"
          >
            <b-form-group label="Discount Percent" label-for="discount-percent">
              <b-form-input
                id="discount-percent"
                v-model="couponData.discountPercent"
                type="number"
                min="0"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Discount Flat -->
          <validation-provider
            #default="validationContext"
            name="Discount Flat"
            rules="required"
          >
            <b-form-group label="Discount Flat" label-for="discount-flat">
              <b-form-input
                id="discount-flat"
                v-model="couponData.discountFlat"
                type="number"
                min="0"
                autofocus
                :state="getValidationState(validationContext)"
                number
                placeholder="0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Enabled -->
          <validation-provider
            #default="validationContext"
            name="Enabled"
            rules="required"
          >
            <b-form-group label="Enabled" label-for="enabled">
               <v-select
                v-model="couponData.isEnabled"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[{label:'Yes', value: true}, {label:'No', value: false}]"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="is-enabled"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- First Order Only -->
          <validation-provider
            #default="validationContext"
            name="First Order Only"
            rules="required"
          >
            <b-form-group label="First Order Only" label-for="first-order-only">
               <v-select
                v-model="couponData.firstOrderOnly"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[{label:'Yes', value: true}, {label:'No', value: false}]"
                :reduce="(val) => val.value"
                :clearable="false"
                :state="getValidationState(validationContext)"
                input-id="first-order-only"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Credit Gift -->
          <validation-provider
            #default="validationContext"
            name="Credit Gift"
            rules="required"
          >
            <b-form-group label="Credit Gift" label-for="credit-gift">
               <v-select
                v-model="couponData.isCreditGift"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[{label:'Yes', value: true}, {label:'No', value: false}]"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="credit-gift"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Start Date -->
          <validation-provider
            #default="validationContext"
            name="Start Date"
          >
            <b-form-group label="Start Date" label-for="start-date">
              <b-form-input
                id="start-date"
                v-model="couponData.startDate"
                type="date"
                autofocus
                :state="getValidationState(validationContext)"
                @change="handleDateChange"
                placeholder=""
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
         
          <!-- End Date -->
          <validation-provider
            #default="validationContext"
            name="End Date"
          >
            <b-form-group label="End Date" label-for="end-date">
              <b-form-input
                id="end-date"
                v-model="couponData.endDate"
                type="date"
                autofocus
                :state="getValidationState(validationContext)"
                placeholder=""
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{isEdit ? 'Update' : 'Add'}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { onMounted, ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import useCoupon from "../useCoupon";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewCouponSidebarActive",
    event: "update:is-add-new-coupon-sidebar-active",
  },
  props: {
    isAddNewCouponSidebarActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    couponToUpdate: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
  
    const toast = useToast();

    let blankCouponData = {
      id: null,
      title: null,
      code: null,
      baseType: null,
      description: 0,
      usageCount: 0,
      minAmount: 0,
      maxAmount: 0,
      minimumPrice: 0,
      discountPercent: 0,
      discountFlat: 0,
      isEnabled: true,
      firstOrderOnly: false,
      isCreditGift: false,
      startDate: null,
      endDate: null
    };

    const couponData = ref(JSON.parse(JSON.stringify(blankCouponData)));
    const resetCouponData = () => {
      couponData.value = JSON.parse(JSON.stringify(blankCouponData));
    };

    const onSubmit = () => {
      if (props.isEdit) {
        dispatchUpdateCoupon();
      } else {
        dispatchAddCoupon();
      }
    };

    const dispatchAddCoupon = () => {
      addCoupon(couponData.value)
        .then((_) => {
          emit("refetch-data");
          emit("update:is-add-new-coupon-sidebar-active", false);
        })
        .catch((message) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const dispatchUpdateCoupon = () => {
      updateCoupon(couponData.value)
        .then((_) => {
           emit("refetch-data");
           emit("update:is-add-new-coupon-sidebar-active", false);
        })
        .catch((message) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const onFormToggle = (value) => {
        emit('update:is-add-new-coupon-sidebar-active', value)
        if(value) {
            if(props.isEdit) {
                blankCouponData = {...props.couponToUpdate};
                couponData.value = blankCouponData
            } else {
                props.isEdit = false;
                blankCouponData = {
                    id: null,
                    title: null,
                    code: null,
                    description: null,
                    usageCount: 0,
                    minAmount: 0,
                    maxAmount: 0,
                    minimumPrice: 0,
                    discountPercent: 0,
                    discountFlat: 0,
                    isEnabled: false,
                    firstOrderOnly: false,
                    isCreditGift: false,
                    startDate: null,
                    endDate: null
                }; 
                couponData.value = blankCouponData
            }
        }
    }

    const handleDateChange = (event) => {
      console.log(event)
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetCouponData);

    const { setCoupon, addCoupon, updateCoupon } = useCoupon();

    return {
      couponData,
      onSubmit,
      handleDateChange,

      refFormObserver,
      getValidationState,
      resetForm,
      onFormToggle
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-driver-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
