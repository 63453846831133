import { ref, watch, computed, reactive } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import  routes from '@/@core/utils/api-routes'
import { paginateArray, sortCompare } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const coupons = ref([])
const filteredCoupons = ref([])

export default function useCoupon() {
  // Use toast
  const toast = useToast()

  const refCouponTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, stickyColumn: true },
    { key: 'title', sortable: true },
    { key: 'code', sortable: true },
    { key: 'description', sortable: true },
    { key: 'usageCount', sortable: true },
    { key: 'discountFlat', sortable: true },
    { key: 'discountPercent', sortable: true },
    { key: 'maxAmount', sortable: true },
    { key: 'minAmount', sortable: true },
    { key: 'startDate', sortable: true },
    { key: 'endDate', sortable: true },
    { key: 'isEnabled', label: "Status", sortable: true },
    { key: 'actions',  },
  ]
  const perPage = ref(10)
  const totalCoupons = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)


  const dataMeta = computed(() => {
    const localItemsCount = refCouponTable.value ? refCouponTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCoupons.value,
    }
  })

  const refetchData = () => {
    console.log("refectchData")
    refCouponTable.value.refresh()
  }
 
  const refreshData = () => {
    coupons.value = []
    fetchCoupons()
  }

  const resolveCouponStatusBadge = status => {
    if (status === true) return 'success'
    if (status === false) return 'secondary'
  }

  watch([currentPage, perPage, searchQuery], () => {
    const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = coupons.value.filter(
    coupon => coupon.title.toLowerCase().includes(queryLowered) ||
    coupon.code.toLowerCase().includes(queryLowered) || coupon.description.toLowerCase().includes(queryLowered))
    
    filteredCoupons.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalCoupons.value = filteredData.length
    refetchData()
  })

  function setCoupon(coupon) {
    coupons.value = coupons.value.filter(x => x.id != coupon.id);
    coupons.value.push(coupon);
    coupons.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredCoupons.value = paginateArray(coupons.value, perPage.value, currentPage.value)
  }

  function removeCoupon(couponId) {
    coupons.value = coupons.value.filter(x => x.id != couponId);
    coupons.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredCoupons.value = paginateArray(coupons.value, perPage.value, currentPage.value)
  }

  const fetchCoupons = () => {
    if(coupons.value?.length) {
      return;
    }
    useJwt.axiosIns.get(routes.COUPON)
      .then(({data}) => {
        data.forEach(element => {
          if(!element.startDate) return
          let startDate = new Date(element.startDate).toISOString().slice(0,10)
          let endDate = new Date(element.endDate).toISOString().slice(0,10)
          element.startDate = startDate
          element.endDate = endDate
          // element.endDate = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`
        });
        coupons.value = data.sort((a, b) => a.updatedAt - b.updatedAt);
        filteredCoupons.value = paginateArray(coupons.value, perPage.value, currentPage.value)
        totalCoupons.value = data.length
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching coupons',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const addCoupon = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.post(routes.COUPON, data)
        .then(({ data }) => {
          let startDate = new Date(data.startDate).toISOString().slice(0,10)
          let endDate = new Date(data.endDate).toISOString().slice(0,10)
          data.startDate = startDate
          data.endDate = endDate
          setCoupon(data)
          toast({
            component: ToastificationContent,
            props: {
              title: `Coupon added successfully`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
  }
  const updateCoupon = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.put(routes.COUPON+`/${data.id}`, data)
        .then(({ data }) => {
          let startDate = new Date(data.startDate).toISOString().slice(0,10)
          let endDate = new Date(data.endDate).toISOString().slice(0,10)
          data.startDate = startDate
          data.endDate = endDate
          setCoupon(data)
          toast({
            component: ToastificationContent,
            props: {
              title: `Update Successfull`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
}
  
    const deleteCoupon = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.delete(routes.COUPON+`/${data.id}`, data)
        .then(({ data }) => {
          // removeCoupon(data.id)
          toast({
            component: ToastificationContent,
            props: {
              title: `Update Successfull`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
  }

  return {
    fetchCoupons,
    addCoupon,
    updateCoupon,
    deleteCoupon,
    tableColumns,
    perPage,
    currentPage,
    totalCoupons,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCouponTable,

    setCoupon,
    removeCoupon,
    refetchData,
    refreshData,
    resolveCouponStatusBadge,
    coupons: computed(() => filteredCoupons.value)
  }
}
