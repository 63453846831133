<template>

  <div>
       <add-new
        :is-add-new-coupon-sidebar-active.sync="isAddNewCouponSidebarActive"
        :is-edit.sync="isEdit"
        :coupon-to-update="couponToUpdate"
        @refetch-data="refetchData"
      />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Referesh -->
          <b-col cols="12"
          md="3">
             <b-button
                variant="primary"
                @click="refreshData()"
              >
               <feather-icon icon="RefreshCcwIcon" />
              </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
                <b-button
                variant="primary"
                @click="openForm()"
                v-if="$can(Action.Create, Subject.COUPON)"
              >
                <span class="text-nowrap">Add Coupon</span>
              </b-button>               
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refCouponTable"
        class="position-relative"
        :items="coupons"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

       <!-- Column: Start Date -->
        <template #cell(startDate)="data">
          <div v-if="data.item.startDate" class="text-nowrap">
            <strong>
              {{
                formatDate(Date.parse(data.item.startDate), {
                  month: "short",
                  day: "numeric",
                  year: "numeric"
                })
              }}
            </strong>
          </div>
        </template>
      
       <!-- Column: End Date -->
        <template #cell(endDate)="data">
          <div v-if="data.item.endDate" class="text-nowrap">
            <strong>
              {{
                formatDate(Date.parse(data.item.endDate), {
                  month: "short",
                  day: "numeric",
                  year: "numeric"
                })
              }}
            </strong>
          </div>
        </template>

         <!-- Column: is Suspended -->
        <template #cell(isEnabled)="data">
          <b-badge
            pill
            :variant="`light-${resolveCouponStatusBadge(
              data.item.isEnabled
            )}`"
            class="text-capitalize"
          >
            {{ data.item.isEnabled ?  "Active":"Inactive" }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="button"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
              <b-dropdown-item @click="editCoupon(data.item)" v-if="$can(Action.Update, Subject.COUPON)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="removeCoupon(data.item)" v-if="$can(Action.Delete, Subject.COUPON)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCoupons"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge,
      BDropdown, BDropdownItem, BDropdownItemButton, BPagination} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import useCoupon from './useCoupon'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AddNew from './components/AddNew.vue'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText, formatDate } from "@core/utils/filter"
import { Action, Subject } from '@/libs/acl/config'

export default {
  components: {
    AddNew,
    BDropdown, 
    BDropdownItem, 
    BDropdownItemButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BBadge,
    vSelect
  },
  directives: {
    Ripple,
  },
   data() {
    return {
      couponToUpdate: null,
    }
  },
  methods: {
    removeCoupon(coupon) {
         this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${coupon.title}.`, {
          title: 'Confirmation',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value) {
            this.onDelete(coupon)
          }
        })
    },
    editCoupon(coupon) {
      this.couponToUpdate = coupon;
      this.openForm(true);
    },
  },
  setup() {

    onMounted(() => {
      fetchCoupons()
    })
    const isAddNewCouponSidebarActive = ref(false)
    const isEdit = ref(false);
    const toast = useToast()


    const openForm = (update = false) => {
      isEdit.value = update;
      isAddNewCouponSidebarActive.value = true;
    };

     const onDelete = (coupon) => {
         deleteCoupon(coupon)
        .then(_ => {
          removeCoupon(coupon.id)
          refetchData()
        }).catch((message) => {
         toast({
            component: ToastificationContent,
            props: {
              title: 'Error: '+message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    };

    const {
      fetchCoupons,
      coupons,      
      tableColumns,
      perPage,
      currentPage,
      totalCoupons,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCouponTable,
      refetchData,
      refreshData,
      deleteCoupon,
      removeCoupon,
      resolveCouponStatusBadge
    } = useCoupon()

    return {
      isAddNewCouponSidebarActive,
      isEdit,

      openForm,
      fetchCoupons,
      deleteCoupon,
      onDelete,
      coupons,
      
      tableColumns,
      perPage,
      currentPage,
      totalCoupons,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCouponTable,
      refetchData,
      refreshData,
      toast,
      formatDate,
      resolveCouponStatusBadge,

      // Authorisation
      Action,
      Subject      
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
